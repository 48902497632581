type ApiResponse = {
  balance: number
  isVip: boolean
}

const API_REQUEST_PATH = '/user/balance'

export function userBalanceGet() {
  return useApiApp<ApiResponse>(API_REQUEST_PATH)
}
