import { useUserStore } from './user.store'

import { userBalanceGet } from '../api/userBalanceGet.api'

export async function balanceLoad(): Promise<boolean> {
  const store = useUserStore()

  const response = await userBalanceGet()

  store.balanceLoaded = true
  if (response.isOk && response.data) {
    store.balance = response.data.balance
    return true
  }
  return false
}

export function balanceEnough(balance: number) {
  const store = useUserStore()
  return store.balance >= balance
}
